import React from 'react';
import img404 from "../../images/404.jpg"
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const ErrorPage = () => {

    const handleClick = () => {
        window.scrollTo(0,0);
    }

    return (
        <div className="row m-lg-3 mt-3 fadein">
            <Helmet>
                <title>Page non trouvée</title>
                <meta name="robots" content="noindex nofollow" />
            </Helmet>
            <div className="card text-white mb-3 rounded" style={{backgroundImage: 'repeating-linear-gradient(#030303, #232323)', backgroundSize: '4px 4px'}}>
                <div className="card-body text-center">
                    <img src={img404} alt="Page non trouvé" className="img-fluid"/>
                    <h1 className="card-title mt-5">Oups ! La page que vous recherchez n'existe pas !</h1>
                    <p className="card-text">On a sûrement dû mal vous renseigner ! Aucun soucis, vous pouvez retourner à l'accueil en cliquant sur le bouton ci-dessous :</p>
                    <Link to="/" className="btn btn-warning" onClick={handleClick}>Retour à l'accueil</Link>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;