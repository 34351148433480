import React from "react";
import { FaCheckSquare } from "react-icons/fa";

const ProSkills = () => {
  return (
    <>
      <h4>Compétences</h4>
      <hr />
      <ul className="list-group list-group-flush rounded-3">
        <li className="list-group-item list-group-item-warning">
          <FaCheckSquare /> Rédaction et application d'un cahier des charges.
        </li>
        <li className="list-group-item list-group-item-warning">
          <FaCheckSquare /> Utilisation de la méthode AGILE (backlog, sprint, revue).
        </li>
        <li className="list-group-item list-group-item-warning">
          <FaCheckSquare /> Développement de sites ou d'applications web avec respect des protocoles de sécurité et des demandes des clients.
        </li>
      </ul>
    </>
  )
};

export default ProSkills;



