import React from 'react';
import MoreBackground from "../../images/more-background.jpg";
import {
    FaInfoCircle,
    FaLaptopCode,
    FaLanguage
} from "react-icons/fa";
import {MdLocalMovies} from "react-icons/md";
import {GiCook, GiMusicSpell} from "react-icons/gi";
import {Helmet} from "react-helmet-async";

const More = () => {

    return (
        <>
            <Helmet>
                <title>Les plus - Thierry Brouet - Développeur web</title>
                <meta name="description" content="Voici la liste de petits plus et des hobbies de Thierry Brouet - Développeur web React." />
            </Helmet>
            <div className="row p-5" style={{background: `url(${MoreBackground})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
                <h1><FaInfoCircle /> LES <span className="text-danger">PLUS</span></h1>
            </div>
            <div className="row m-lg-3 mt-3 fadein">
                <div className="card text-white mb-3 rounded" style={{backgroundImage: 'repeating-linear-gradient(#030303, #232323)', backgroundSize: '4px 4px'}}>
                    <div className="card-body">
                        <h4>LOISIRS</h4>
                        <hr/>
                        <ul className="list-group list-group-flush rounded-3">
                            <li className="list-group-item list-group-item-warning">
                                <MdLocalMovies /> Cinéma | <GiCook /> Cuisine | <GiMusicSpell /> Musique | <FaLaptopCode /> Et bien sûr l'informatique !
                            </li>
                        </ul>
                        <h4 className="mt-3">LANGUES</h4>
                        <hr/>
                        <ul className="list-group list-group-flush rounded-3">
                            <li className="list-group-item list-group-item-warning">
                                <FaLanguage /> Anglais : Niveau A2 (lu, parlé, écrit) + technique.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default More;