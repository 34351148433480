import React from 'react';

const Footer = () => {

    return (
        <footer className="row bg-light mt-5 p-5">
            <p className="text-center">&copy; 2021 - { new Date().getFullYear() } thierry-brouet.fr</p>
        </footer>
    );
}

export default Footer;