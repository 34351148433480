import React from 'react';
import ExperienceBackground from "../../images/experience-background.jpg";
import {FaLaptopCode} from "react-icons/fa";
import {dataExperience} from "../../data/dataExperience";
import {Helmet} from "react-helmet-async";

const Experience = () => {

    return (
        <>
            <Helmet>
                <title>Expérience pro - Thierry Brouet - Développeur web</title>
                <meta name="description" content="Voici la liste de toutes les expériences professionnelles de Thierry Brouet - Développeur web React." />
            </Helmet>
            <div className="row p-5" style={{background: `url(${ExperienceBackground})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
                <h1><FaLaptopCode /> EXPÉRIENCES <span className="text-danger fw-bold">PROFESSIONNELLES</span></h1>
            </div>
            <div className="row m-lg-3 mt-3 fadein">
                <div className="card text-white mb-3 rounded" style={{backgroundImage: 'repeating-linear-gradient(#030303, #232323)', backgroundSize: '4px 4px'}}>
                    <div className="card-body">
                        { dataExperience.sort((a,b) => {
                            return b.id - a.id
                        })
                            .map( experience =>
                            <div key={experience.id}>
                            <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                            <div className="flex-grow-1">
                                <h4 className="mb-0">{experience.title}</h4>
                                <div className="subheading mb-3">{experience.company}</div>
                            </div>
                            <div className="flex-shrink-0"><h4 className="text-danger">{experience.years}</h4></div>
                            </div>
                        </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Experience;