export const dataPortfolio = [
    {
        id: 2,
        title: "Fashion",
        subTitle: "Une page d'accueil exemple toute simple",
        image: "../../thumbnails/thumbnail-fashion.jpg",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-bootstrap.png",
                language: "Bootstrap 4"
            }
        ],
        description: "Page développée en un week-end pour tester ce que je venais d'apprendre avec le framework css Bootstrap 4.",
        url: "https://thierry-fashion.web.app/"
    },
    {
        id: 3,
        title: "Mistergratos",
        subTitle: "Plateforme de téléchargements de fonds d'écran",
        image: "../../thumbnails/thumbnail-mistergratos.jpg",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-bootstrap.png",
                language: "Bootstrap 5"
            },
            {
                id: 4,
                icon: "../../icons/mini-icon-twig.png",
                language: "Twig"
            },
            {
                id: 5,
                icon: "../../icons/mini-icon-javascript.png",
                language: "Javascript"
            },
            {
                id: 6,
                icon: "../../icons/mini-icon-react.png",
                language: "React"
            },
            {
                id: 7,
                icon: "../../icons/mini-icon-php.png",
                language: "PHP 7"
            },
            {
                id: 8,
                icon: "../../icons/mini-icon-symfony.png",
                language: "Symfony 5"
            },
            {
                id: 9,
                icon: "../../icons/mini-icon-mysql.png",
                language: "MySQL"
            },
        ],
        libraries: [
            {
                id: 1,
                library: "Axios"
            },
            {
                id: 2,
                library: "Webpack Encore"
            },
            {
                id: 3,
                library: "Easyadmin V3"
            },
            {
                id: 4,
                library: "VichUploader"
            },

        ],
        description: "Projet personnel réalisé dans le cadre de ma certification de développeur web et web mobile.",
        url: "https://mistergratos.fr/"
    },
    {
        id: 4,
        title: "API Google Books - React",
        subTitle: "Recherche de livres numériques sur l'API Google Books",
        image: "../../thumbnails/thumbnail-api-google-books-react.jpg",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-bootstrap.png",
                language: "Bootstrap 5"
            },
            {
                id: 4,
                icon: "../../icons/mini-icon-react.png",
                language: "React"
            },

        ],
        libraries: [
            {
                id: 1,
                library: "Axios"
            },

        ],
        description: "Petit projet d'entraînement sur la librairie Javascript React avec l'API de Google Books via Axios.",
        url: "https://api-books-react.web.app/"
    },
    {
        id: 5,
        title: "Arche du 62 (En collaboration avec Sébastien Vermelle)",
        subTitle: "Site web pour une association de protection animale",
        image: "../../thumbnails/thumbnail-archedu62.jpg",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-bootstrap.png",
                language: "Bootstrap 5"
            },
            {
                id: 4,
                icon: "../../icons/mini-icon-react.png",
                language: "React"
            },
            {
                id: 5,
                icon: "../../icons/mini-icon-javascript.png",
                language: "Javascript"
            },
            {
                id: 6,
                icon: "../../icons/mini-icon-php.png",
                language: "PHP 7"
            },
            {
                id: 7,
                icon: "../../icons/mini-icon-symfony.png",
                language: "Symfony 5"
            },
            {
                id: 8,
                icon: "../../icons/mini-icon-mysql.png",
                language: "MySQL"
            },
        ],
        libraries: [
            {
                id: 1,
                library: "Axios"
            },
            {
                id: 2,
                library: "Webpack Encore"
            },
            {
                id: 3,
                library: "Easyadmin V3"
            },
            {
                id: 4,
                library: "VichUploader"
            },
        ],
        description: "Refonte totale de leur site web datant de plus de dix ans.",
        url: "https://archedu62.fr/"
    },
    {
        id: 6,
        title: "App Geo Gouv",
        subTitle: "Recherche de villes de France par leur code postaux",
        image: "../../thumbnails/thumbnail-app-geo-gouv.jpg",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-bootstrap.png",
                language: "Bootstrap 5"
            },
            {
                id: 4,
                icon: "../../icons/mini-icon-react.png",
                language: "React"
            },
            {
                id: 5,
                icon: "../../icons/mini-icon-javascript.png",
                language: "Javascript"
            },
        ],
        libraries: [
            {
                id: 1,
                library: "Axios"
            },

        ],
        description: "Projet personnel réalisé pour m'entrainer à interroger l'API Géoportail. Celui-ci permet de rechercher une ville dans toute la France (DOM compris) au moyen de son code postal.",
        url: "https://thierry-api-geo-gouv.web.app/"
    },
    {
        id: 7,
        title: "React API Crypto",
        subTitle: "Donne le court de la crypto-monnaie",
        image: "../../thumbnails/thumbnail-react-api-crypto.jpg",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-bootstrap.png",
                language: "Bootstrap 5"
            },
            {
                id: 4,
                icon: "../../icons/mini-icon-react.png",
                language: "React"
            },
            {
                id: 5,
                icon: "../../icons/mini-icon-javascript.png",
                language: "Javascript"
            },
        ],
        libraries: [
            {
                id: 1,
                library: "Axios"
            },

        ],
        description: "Projet personnel réalisé pour m'entrainer à interroger l'API CoinGecko. Cette API permet d'avoir beaucoup de données dans le domaine de la crypto-monnaie.",
        url: "https://react-api-crypto-traking.netlify.app/"
    },
    {
        id: 8,
        title: "React Parallax",
        subTitle: "Différents effets de parallax",
        image: "../../thumbnails/thumbnail-react-parallax.jpg",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-bootstrap.png",
                language: "Bootstrap 5"
            },
            {
                id: 4,
                icon: "../../icons/mini-icon-react.png",
                language: "React"
            },
            {
                id: 5,
                icon: "../../icons/mini-icon-javascript.png",
                language: "Javascript"
            },
        ],
        libraries: [
            {
                id: 1,
                library: "React Parallax"
            },

        ],
        description: "Projet personnel réalisé pour voir l'utilisation de la librairie à effets : \"React Parallax\".",
        url: "https://react-parallax-example.web.app/"
    },
    {
        id: 9,
        title: "Fun Fake Parody",
        subTitle: "Site parodique humoristique",
        image: "../../thumbnails/thumbnail-fun-fake-parody.png",
        languages: [
            {
                id: 1,
                icon: "../../icons/mini-icon-html.png",
                language: "HTML 5"
            },
            {
                id: 2,
                icon: "../../icons/mini-icon-css.png",
                language: "CSS 3"
            },
            {
                id: 3,
                icon: "../../icons/mini-icon-react.png",
                language: "React 18"
            },
            {
                id: 4,
                icon: "../../icons/mini-icon-javascript.png",
                language: "Javascript"
            },
        ],
        description: "Projet que j'ai développé en une petite semaine après avoir découvert l'application WOMBO qui permet de faire des vidéos fake en chanson à l'aide d'une simple photo.",
        url: "https://fun-fake-parody.fr/"
    },
];