export const dataEducation = [
    {
        id: 1,
        title: "Mécanicien réparateur d'automobiles",
        school: "GARAGE PLESSIER LAIGNEVILLE (60)",
        degree: "CAP",
        years: "1988"
    },
    {
        id: 2,
        title: "Administration Commerciale & Comptable",
        school: "ECOLE PIGIER BEAUVAIS (60)",
        degree: "CAP & BEP",
        years: "1990"
    },
    {
        id: 3,
        title: "Techniques Quantitatives de Gestion",
        school: "LYCÉE CASSINI CLERMONT (60)",
        degree: "BACCALAURÉAT G2",
        years: "1993"
    },
    {
        id: 4,
        title: "Développeur Web & Web Mobile",
        school: "AFPA LIÉVIN (62)",
        degree: "TITRE PROFESSIONNEL",
        years: "2020"
    },
    {
        id: 5,
        title: "Concepteur Développeur d'Application",
        school: "AFPA ARRAS (62)",
        degree: "TITRE PROFESSIONNEL",
        years: "2021"
    },
    {
        id: 6,
        title: "Développeur Grands Systèmes COBOL",
        school: "M2i FORMATION VILLENEUVE D'ASCQ (59)",
        degree: "",
        years: "2021 - 2022"
    },
    {
        id: 7,
        title: "Chef de projet informatique",
        school: "AFPI ARRAS(62)",
        degree: "",
        years: "2022"
    }
]