import React from 'react';
import {FaLightbulb} from "react-icons/fa";
import PortfolioBackground from "../../images/portfolio-background.jpg";
import Projects from "./components/Projects";

const Portfolio = () => {

    return (
        <>
            <div className="row p-5" style={{background: `url(${PortfolioBackground})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
                <h1><FaLightbulb /> MES <span className="text-danger fw-bold">RÉALISATIONS</span></h1>
            </div>

            <div className="row m-lg-3 mt-3 fadein">
                <div className="card text-white mb-3 rounded" style={{backgroundImage: 'repeating-linear-gradient(#030303, #232323)', backgroundSize: '4px 4px'}}>
                    <div className="card-body">
                        <Projects />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Portfolio;