import React from 'react';
import {HiOutlineMail} from "react-icons/hi";
import {Link} from "react-router-dom";


// Close Bootstrap burger menu on click on Navlinks
const handleCollapse = () => {
    const nav = document.getElementById("navbarNav");
    const btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
    window.scrollTo(0,0);
};

const ContactButton = () => {

    return (
        <div className="element-fixed">
            <Link to="/me-contacter"><div className="btn btn-sm btn-success shadow-lg border bounce" onClick={handleCollapse}><HiOutlineMail /> Me contacter</div></Link>
        </div>
    );
};

export default ContactButton;