import React from 'react';
import EducationBackground from "../../images/education-background.jpg";
import {FaGraduationCap} from "react-icons/fa";
import {dataEducation} from "../../data/dataEducation";
import {Helmet} from "react-helmet-async";

const Education = () => {

    return (
        <>
            <Helmet>
                <title>Formation - Thierry Brouet - Développeur web</title>
                <meta name="description" content="Voici la liste de toutes les formations de Thierry Brouet - Développeur web React." />
            </Helmet>
            <div className="row p-5" style={{background: `url(${EducationBackground})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
                <h1><FaGraduationCap /> FORMATION</h1>
            </div>
            <div className="row m-lg-3 mt-3 fadein">
                <div className="card text-white mb-3 rounded" style={{backgroundImage: 'repeating-linear-gradient(#030303, #232323)', backgroundSize: '4px 4px'}}>
                    <div className="card-body">
                        { dataEducation.sort((a,b) => {
                            return b.id - a.id
                        })
                            .map( education =>
                                <div key={education.id}>
                                    <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-0">{education.title}</h4>
                                            <div className="subheading mb-3">{education.degree}</div>
                                            <p>{education.school}</p>
                                        </div>
                                        <div className="flex-shrink-0"><h4 className="text-danger">{education.years}</h4></div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Education;