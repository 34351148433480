import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import AboutMe from "./pages/AboutMe/AboutMe";
import Experience from "./pages/Experience/Experience";
import Education from "./pages/Education/Education";
import Skills from "./pages/Skills/Skills";
import Portfolio from "./pages/Portfolio/Portfolio";
import More from "./pages/More/More";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import ContactButton from "./components/ContactButton/ContactButton";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer/Footer";
import {HelmetProvider} from "react-helmet-async";

function App() {
    return (
        <div className="container-fluid">
            <HelmetProvider>
                <Router>
                    <Header />
                    <Switch>
                        <Route exact path="/" component={AboutMe}/>
                        <Route path="/experience" component={Experience} />
                        <Route path="/formation" component={Education} />
                        <Route path="/competences" component={Skills} />
                        <Route path="/portfolio" component={Portfolio} />
                        <Route path="/les-plus" component={More} />
                        <Route path="/me-contacter" component={Contact} />
                        <Route component={ErrorPage} />
                    </Switch>
                    <ContactButton />
                    <Footer />
                </Router>
            </HelmetProvider>
        </div>
    );
}

export default App;
