import React, {useState} from 'react';
import ContactBackground from "../../images/contact-background.jpg";
import {MdContactMail} from "react-icons/md";
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';
import {ReCAPTCHA} from "react-google-recaptcha";
import {Helmet} from "react-helmet-async";

const Contact = () => {

    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data, e) => {
        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            e.target,
            process.env.REACT_APP_USER_ID
        )
            .then(res => {
                setSuccess(true);
            })
            .catch(err => {
                setError(true);
            })
        ;
        e.target.reset();
        window.scrollTo(0,0);
        setTimeout(() => {
            setSuccess(false);
            setError(false);
        }, 3000);
    };

    return (
        <>
            <Helmet>
                <title>Contactez-moi - Thierry Brouet - Développeur web</title>
                <meta name="description" content="Un devis ?  Une mission ? Des infos ? Contactez-moi - Thierry Brouet - Développeur web React." />
            </Helmet>
            <div className="row p-5" style={{background: `url(${ContactBackground})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
                <h1><MdContactMail /> ME <span className="text-danger fw-bold">CONTACTER</span></h1>
            </div>
            <div className="row m-lg-3 mt-3 fadein justify-content-center">
                <div className="col-md-8 col-lg-5">
                    {
                        success && <p className="alert alert-success">Votre message à bien été envoyée !</p>
                    }
                    {
                        error && <p className="alert alert-danger">Une erreur est survenue, merci de réessayer plus tard !</p>
                    }
                    <div className="card text-white mb-3 rounded" style={{backgroundImage: 'repeating-linear-gradient(#030303, #232323)', backgroundSize: '4px 4px'}}>
                        <div className="card-body">
                            <h5>Pour me contacter merci de remplir le formulaire suivant :</h5>
                            <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Nom*</label>
                                    <input {...register("name", { required: true })}
                                           type="text"
                                           className="form-control"
                                           id="name"
                                           placeholder="Saisir votre nom..."
                                    />
                                    {errors.name && <p className="small alert alert-danger p-1">Veuillez saisir un nom...</p>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="company" className="form-label">Société</label>
                                    <input {...register("company")}
                                           type="text"
                                           className="form-control"
                                           id="company"
                                           placeholder="Saisir votre société..."
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email*</label>
                                    <input {...register("email", { required: true })}
                                           type="email"
                                           className="form-control"
                                           id="email"
                                           placeholder="nom@exemple.fr"
                                    />
                                    {errors.email && <p className="small alert alert-danger p-1">Veuillez saisir une adresse email...</p>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Votre message*</label>
                                    <textarea {...register("message", { required: true })}
                                              className="form-control"
                                              id="message"
                                              rows="3"
                                              placeholder="Saisir votre message..."
                                    />
                                    {errors.message && <p className="small alert alert-danger p-1">Veuillez saisir un message...</p>}
                                </div>
                                <input type="submit" className="btn btn-warning" value="Valider" />
                                <p className="mt-3"><small>Tous les champs marqués de * sont obligatoires</small></p>
                                <ReCAPTCHA
                                    sitekey="6Le2oq8bAAAAAA65yzfVDT7MesHHZOwpeiN1eULu"
                                    onChange={onSubmit}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;