import React from 'react';
import photo from '../../images/profile-photo.png'
import Background from '../../images/background.jpg';
import {Link, NavLink} from "react-router-dom";
import {FaDownload} from "react-icons/fa";

// Close Bootstrap burger menu on click on Navlinks
const handleCollapse = () => {
    const nav = document.getElementById("navbarNav");
    const btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
    window.scrollTo(0,0);
};

const Header = () => {
    return (
        <header className="row fixed-top">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark" style={{background: `url(${Background})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/"><img id="profile-photo" src={photo} alt="Thierry Brouet"/></Link>
                    <button
                        className="navbar-toggler"
                        id="navbarBtn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to="/" onClick={handleCollapse}>À propos de moi</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/experience" onClick={handleCollapse}>Expérience</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/formation" onClick={handleCollapse}>Formation</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/competences" onClick={handleCollapse}>Compétences</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/portfolio" onClick={handleCollapse}>Portfolio</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/les-plus" onClick={handleCollapse}>Les plus</NavLink>
                            </li>
                            <li className="nav-item">
                            <span onClick={handleCollapse}>
                                <a
                                    className="nav-link"
                                    href="../../pdf/CV-Thierry-Brouet-cobol.pdf"
                                    target="_blank" rel="noreferrer noopener"><FaDownload /> Télécharger ce CV
                                </a>
                            </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;