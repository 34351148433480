import React from 'react';
import AboutMeBackground from '../../images/about-me-background.jpg';
import {FaUserTie, FaLinkedinIn, FaGithub} from "react-icons/all";
import dataAboutMe from "../../data/dataAboutMe";
import dataMeta from "../../data/dataMeta";
import {Helmet} from "react-helmet-async";

const AboutMe = () => {

    return (

        <section className="row bg-light p-5 fadein text-shadow" style={{background: `url(${AboutMeBackground})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
            <Helmet>
                <title>{dataMeta.title}</title>
                <meta name="description" content={dataMeta.description}/>
            </Helmet>
            <h1>
                <FaUserTie /> BROUET
                <span className="text-danger fw-bold"> Thierry</span>
            </h1>
            <h3>
                {dataAboutMe.zipcode} {dataAboutMe.city} · {dataAboutMe.phoneNumber}
            </h3>
            <h4 className="my-5">{dataAboutMe.job}</h4>
            <p>{dataAboutMe.description}</p>
            <p className="mt-5 text-center">
                <a href={dataAboutMe.linkedin}><FaLinkedinIn className="icons"/></a>
                <a href={dataAboutMe.github}><FaGithub className="icons"/></a>
            </p>
        </section>

    );
}

export default AboutMe;