import React from 'react';
import { FaHtml5, FaCss3Alt, FaJsSquare, FaReact, FaPhp, FaSymfony, FaNpm, FaYarn, FaGithub, FaCheckSquare } from "react-icons/fa";

const Stackskills = () => {
    return (
        <>
            <h4 className='mt-5'>Langages de programmation et outils</h4>
            <hr />
            <ul className="list-inline text-center text-warning mt-5">
                <li className="list-inline-item"><FaHtml5 className="icons shadow-lg border border-danger rounded-pill" title="HTML 5" /></li>
                <li className="list-inline-item"><FaCss3Alt className="icons shadow-lg border border-danger rounded-pill" title="CSS 3" /></li>
                <li className="list-inline-item"><FaJsSquare className="icons shadow-lg border border-danger rounded-pill" title="Javascript" /></li>
                <li className="list-inline-item"><FaReact className="icons shadow-lg border border-danger rounded-pill" title="React" /></li>
                <li className="list-inline-item"><FaPhp className="icons shadow-lg border border-danger rounded-pill" title="PHP" /></li>
                <li className="list-inline-item"><FaSymfony className="icons shadow-lg border border-danger rounded-pill" title="Symfony" /></li>
                <li className="list-inline-item"><FaNpm className="icons shadow-lg border border-danger rounded-pill" title="NPM" /></li>
                <li className="list-inline-item"><FaYarn className="icons shadow-lg border border-danger rounded-pill" title="Yarn" /></li>
                <li className="list-inline-item"><FaGithub className="icons shadow-lg border border-danger rounded-pill" title="Github" /></li>
            </ul>
            <h4>PARTIE FRONT-END</h4>
            <hr />
            <ul className="list-group list-group-flush rounded-3">
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Création d'une maquette d'une application selon la demande du client et du cahier des
                    charges.
                </li>
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Mise en place d'une interface utilisateur web statique avant validation du client.
                </li>
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Développement dynamique de l'interface utilisateur.
                </li>
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Réalisation d'un interface client avec une solution de gestion de contenu et / ou
                    e-commerce.
                </li>
            </ul>
            <h4 className="mt-3">PARTIE BACK-END</h4>
            <hr />
            <ul className="list-group list-group-flush rounded-3">
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Créer une base de données.
                </li>
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Développer les composants d’accès aux données.
                </li>
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Développer la partie back-end d’une application web ou web mobile.
                </li>
                <li className="list-group-item list-group-item-warning">
                    <FaCheckSquare /> Elaborer et mettre en oeuvre des composants dans une application de gestion de contenu ou e-commerce.
                </li>
            </ul>
        </>
    );
}

export default Stackskills;
