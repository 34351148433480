import React from 'react';
import ProSkills from './Components/ProSkills';
import Stackskills from './Components/StackSkills';
import SkillsBackground from "../../images/skills-background.jpg";
import { FaAtlas } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const Skills = () => {

    return (
        <>
            <Helmet>
                <title>Compétences - Thierry Brouet - Développeur web</title>
                <meta name="description" content="Voici la liste de toutes les compétences de Thierry Brouet - Développeur web React." />
            </Helmet>
            <div className="row p-5" style={{ background: `url(${SkillsBackground})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <h1><FaAtlas /> COMPÉTENCES</h1>
            </div>

            <div className="row m-lg-3 mt-3 fadein">
                <div className="card text-white mb-3 rounded" style={{ backgroundImage: 'repeating-linear-gradient(#030303, #232323)', backgroundSize: '4px 4px' }}>
                    <div className="card-body">
                        <ProSkills />
                        <Stackskills />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Skills;