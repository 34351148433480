export const dataExperience = [
    {
        id: 1,
        title: "MICRO ENTREPRISE DE VENTE DE CD, DVD ET JEUX VIDÉOS NEUFS AVEC GESTION D'UNE BOUTIQUE EN LIGNE",
        company: "DIPLO STORE - CLERMONT (60)",
        years: "2007 - 2008"
    },
    {
        id: 2,
        title: "MAGASINIER - GESTIONNAIRE DE STOCKS AU SERVICE RESTAURANT D'APPLICATION",
        company: "LYCEE ANDRE MALRAUX - BÉTHUNE (62)",
        years: "2010 - 2012"
    },
    {
        id: 3,
        title: "MAGASINIER - GESTIONNAIRE DE STOCKS",
        company: "THERM'OPALE - CARVIN (62)",
        years: "2013 - 2015"
    },
    {
        id: 4,
        title: "MAGASINIER - GESTIONNAIRE DE STOCKS",
        company: "DEPANN'GAZ SERVICE - CARVIN (62)",
        years: "2015 - 2018"
    },
    {
        id: 5,
        title: "DÉVELOPPEUR WEB (REFONTE TOTALE DU SITE)",
        company: "L'ARCHE DU 62 BARLIN (62)",
        years: "2021"
    },
    {
        id: 6,
        title: "FREELANCE DÉVELOPPEUR WEB REACT - PHP / SYMFONY",
        company: "AUTO-ENTREPRENEUR",
        years: "2021"
    },
    {
        id: 7,
        title: "DÉVELOPPEUR COBOL",
        company: "INETUM - LILLE (59)",
        years: "2022 - AUJOURD'HUI"
    },

];