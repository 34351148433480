import React from 'react';
import {dataPortfolio} from "../../../data/dataPortfolio";
import {Helmet} from "react-helmet-async";

const Projects = () => {
    return (
        <>
            <Helmet>
                <title>Mes réalisations - Thierry Brouet - Développeur web</title>
                <meta name="description" content="Voici la liste de toutes les réalisations de Thierry Brouet - Développeur web React." />
            </Helmet>
            {
                dataPortfolio.sort((a,b) => {
                    return b.id - a.id
                })
                    .map(project =>
                        <div className="row flex-md-row flex-column text-center align-items-center border border-warning p-lg-5 rounded-custom mb-3" key={project.id}>
                            <h3 className="card-title mb-5">{project.title}</h3>
                            <div className="col mb-2">
                                <img src={project.image}
                                     className="align-self-center mr-3 img-thumbnail shadow-lg"
                                     alt={project.title} />
                            </div>
                            <div className="col">
                                <h5>{project.subTitle}</h5>
                                <hr/>
                                {project.languages &&
                                <>
                                    <p className="fw-bold text-decoration-underline">Technos utilisées</p>
                                    {project.languages.map(language =>
                                        <p key={language.id}>
                                            <img src={language.icon} alt={language.language}/> <span>{language.language}</span>
                                        </p>
                                    )}
                                </>
                                }
                                {project.libraries &&
                                <>
                                    <p className="fw-bold text-decoration-underline">Bibliothèques, plugins, bundles</p>
                                    {project.libraries.map(library =>
                                        <p key={library.id}>
                                            {library.library}
                                        </p>
                                    )}
                                </>
                                }
                                <p>{project.description}</p>
                                <a className="btn btn-warning mb-5 shadow-lg" href={project.url}>
                                    Visiter le site
                                </a>
                            </div>
                        </div>
                    )
            }
        </>
    );
};

export default Projects;